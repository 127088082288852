import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { API_BASE_URL } from "../utils/constants";
import { getToken } from "../utils/GlobalFunction";
import { json } from "react-router-dom";

const initialState = {
  id: null,
  userName: null,
  loading: false,
  isLoggedIn: false,
  userData: null,
  error: null,
  access_token: null,
  users_List: null,
  user_roles: null,
  completedTasks: null,
  all_user_list: null,
  organizations: null,
};

let controller = new AbortController();
const resetController = () => {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
};



export const LoginAuth = createAsyncThunk("User/Login", async (myData) => {
  axios.defaults.withCredentials = true;
  resetController();
  const response = await axios({
    withCredentials: true,
    method: "post",
    url: `${API_BASE_URL}/User/login`,
    data: myData,
    headers: { "Content-Type": "multipart/form-data" },
    signal: controller.signal
  });

  const data = (await response).data;

  return data;
});

export const LogoutAuth = createAsyncThunk("User/Logout", async () => {
  axios.defaults.withCredentials = true;
  resetController();
  const response = await axios({
    withCredentials: true,
    method: "post",
    url: `${API_BASE_URL}/User/logout`,
    signal: controller.signal
  });

  const log = (await response).data;
  return log;
});

export const loadUsers = createAsyncThunk("User/UserList", async () => {
  const token = getToken();

  const response = await axios({
    withCredentials: true,
    method: "get",
    url: `${API_BASE_URL}/User/user_listing`,
    headers: { Accesstoken: token },
  });

  const data = (await response).data;
  removeToken(data.status_code);
  sessionTimeoutCheck(response.data);
  return data;
});

export const dashboard = createAsyncThunk("User/Dashboard", async () => {
  const token = getToken();
  const response = await axios({
    withCredentials: true,
    method: "get",
    url: `${API_BASE_URL}/Dashboard/dashboardWrapper`,
    headers: { Accesstoken: token },
  });

  const data = (await response).data;
  removeToken(data.status_code);
  sessionTimeoutCheck(response.data);
  return data;
});

export const sideMenu = createAsyncThunk("User/SidebarMenu", async () => {
  const token = getToken();
  const response = await axios({
    withCredentials: true,
    method: "get",
    url: `${API_BASE_URL}/Dashboard/sidemenuManagement`,
    headers: { Accesstoken: token },

  });
  const data = (await response).data;
  removeToken(data.status_code);
  sessionTimeoutCheck(response.data);
  return data;
});

export const dashboardFilter = createAsyncThunk(
  "User/DashboardFilter",
  async (myData) => {
    const token = getToken();

    const response = await axios({
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/Dashboard/dashboardWrapper`,
      headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
      data: { filter: myData },
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data);
    return data;
  }
);

export const loadRoles = createAsyncThunk("User/roles", async () => {
  const token = getToken();

  const response = await axios({
    withCredentials: true,
    method: "get",
    url: `${API_BASE_URL}/Dashboard/getUserRoles`,
    headers: { Accesstoken: token },
  });

  const data = (await response).data;
  removeToken(data.status_code);
  sessionTimeoutCheck(response.data);
  return data;
});

export const getAllUsersList = createAsyncThunk("User/getAllUsersList", async () => {
  const token = getToken();

  const response = await axios({
    withCredentials: true,
    method: "get",
    url: `${API_BASE_URL}/Projects/getAllUsersList`,
    headers: { Accesstoken: token },
  });

  const data = (await response).data;
  removeToken(data.status_code);
  sessionTimeoutCheck(response.data);
  return data;
});

export const getUserListByTeam = createAsyncThunk(
  "User/getUserListByTeam",
  async () => {
    const token = getToken();

    const response = await axios({
      withCredentials: true,
      method: "get",
      url: `${API_BASE_URL}/Projects/userListTeam`,
      headers: { Accesstoken: token },
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data);
    return data;
  }
);



export const updateOrganization = createAsyncThunk("User/updateOrganization", async (myData) => {
    console.log(myData);
    

    const token = getToken();
    const response = await axios({
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/User/setLoginSession/${myData.orgId}/${myData.user_id}`,
      headers: { Accesstoken: token },
    });
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data);
    return data;
  });
  


const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    //    LOGIN:(state,action) =>{
    //         state.loading = action.payload.loading
    //         state.isLoggedIn = action.payload.loggedin
    //         state.access_token = action.payload.access_token
    //    },
    LOGGED_IN_USER: (state, action) => {
      state.isLoggedIn = action.payload.loggedin;
      state.loading = action.payload.loading;
      state.userData = action.payload.data;
    },
    LOGOUT: (state, action) => {
      state.id = null;
      state.userEmail = null;
      state.userName = null;
      state.loading = false;
      state.isLoggedIn = false;
      state.isActive = false;
      state.userData = null;
      state.error = null;
      state.access_token = null;
    },
    ChangeOrganization: (state, action) => {
        state.organizations = {
            ...state.organizations,
            selected_org: state.organizations.organization_list.find(org => org.id === action.payload),
        };
        // console.log(JSON.parse(JSON.stringify(state.organizations)));
        
    }

  },
  extraReducers: (builder) => {
    builder
      //login
      .addCase(LoginAuth.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
        state.message = null;
      })
      .addCase(LoginAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.error = action.payload?.errors;
        state.access_token = action.payload?.data?.cstmLoginAccessToken;
        state.message = action.payload?.msg;
        if (action.payload?.data?.cstmLoginAccessToken) {
          localStorage.removeItem("access_token");
          localStorage.setItem(
            "access_token",
            action.payload?.data?.cstmLoginAccessToken
          );
          // Cookies.remove('access_token')
          // var Minutes = new Date(new Date().getTime() + 120 * 60 * 1000);
          // Cookies.set('access_token', action.payload?.data?.cstmLoginAccessToken, {expires: Minutes});
        }
      })
      .addCase(LoginAuth.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
      })

      //logout
      .addCase(LogoutAuth.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
        state.message = null;
      })
      .addCase(LogoutAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.error = null;
        state.access_token = null;
        state.message = null;

        localStorage.removeItem("access_token");
      })
      .addCase(LogoutAuth.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
        state.message = null;
      })

      //loadUser
      .addCase(loadUsers.pending, (state, action) => {
        state.loading = true;
        state.users_List = null;
        state.userData = null;
      })
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users_List = action.payload.data;
      })
      .addCase(loadUsers.rejected, (state, action) => {
        state.loading = false;
        state.users_List = null;
      })

      //dashboard
      //loadUser
      .addCase(dashboard.pending, (state, action) => {
        state.isLoggedIn = false;
        state.loading = true;
        state.userData = null;
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        const dashboardData = action.payload.data?.tasks_in_progress.map(
          (row, i) => ({
            cid: i + 1,
            ...row,
          })
        );

        state.isLoggedIn = false;
        state.loading = false;
        state.userData = action.payload.data;
        state.completedTasks = dashboardData;
      })
      .addCase(dashboard.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.userData = null;
      })

      //loadRoles
      .addCase(loadRoles.pending, (state, action) => {
        state.user_roles = null;
      })
      .addCase(loadRoles.fulfilled, (state, action) => {
        state.user_roles = action.payload.data;
      })
      .addCase(loadRoles.rejected, (state, action) => {
        state.user_roles = null;
      })

      //loadRoles
      .addCase(getAllUsersList.pending, (state, action) => {
        state.all_user_list = null;
      })
      .addCase(getAllUsersList.fulfilled, (state, action) => {
        state.all_user_list = action.payload.data.owner_list;
      })
      .addCase(getAllUsersList.rejected, (state, action) => {
        state.all_user_list = null;
      })

      //loadRoles
      .addCase(sideMenu.pending, (state, action) => {
        state.organizations = null;
      })
      .addCase(sideMenu.fulfilled, (state, action) => {
        state.organizations = action.payload.data1;
      })
      .addCase(sideMenu.rejected, (state, action) => {
        state.organizations = null;
      })

      .addCase(updateOrganization.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
        state.message = null;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.error = action.payload?.errors;
        state.access_token = action.payload?.data?.cstmLoginAccessToken;
        state.message = action.payload?.msg;
        if (action.payload?.data?.cstmLoginAccessToken) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("dashboardData");
            localStorage.removeItem("userInvitationData");
            localStorage.removeItem("projectsData");
            localStorage.removeItem("Myperformance");
          localStorage.setItem(
            "access_token",
            action.payload?.data?.cstmLoginAccessToken
          );
          // Cookies.remove('access_token')
          // var Minutes = new Date(new Date().getTime() + 120 * 60 * 1000);
          // Cookies.set('access_token', action.payload?.data?.cstmLoginAccessToken, {expires: Minutes});
        }
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.access_token = null;
        state.error = null;
      });






  },
});

export const { LOGIN, LOGGED_IN_USER, LOGOUT, CLEAR_DASHBOARD,ChangeOrganization } =
  usersSlice.actions;

export const getActiveUser = (state) => state.users;
export const isLoggedIn = (state) => state.users.isLoggedIn;
export const Loading = (state) => state.users.loading;
export const Access_Token = (state) => state.users.access_token;
export const Login_Errors = (state) => state.users.error;
export const Login_Message = (state) => state.users.message;
export const UserList = (state) => state.users.users_List;
export const UserRoles = (state) => state.users.user_roles;
export const DashboardState = (state) => state.users.userData;
export const CompletedTasks = (state) => state.users.completedTasks;
export const AllUsersList = (state) => state.users.all_user_list;

export default usersSlice.reducer;
