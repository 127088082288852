import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";
import { getToken } from "../utils/GlobalFunction";
import { json } from "react-router-dom";


const initialState = {
    Productivity : null,
    timelog : null,
    searchTimelog: null,
    myTeamtimelog : null,
    myTeamsearchTimelog: null,
    final_records: null,
    loading : null
}

export const loadMyPerformance = createAsyncThunk("myPerformace/loadPerformanceData", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/totalLoggedTimeWrapper`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {performance_type:myData.performanceType}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  })
  export const serachMyPerformance = createAsyncThunk("myPerformace/serachPerformanceData", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,bill_non_bill_filter:myData.BillType}
        
      });
        
      const data = (await response).data; 
      removeToken(data.data); 
      return data;  
  
  })


  
export const loadMyTeamPerformance = createAsyncThunk("myPerformace/loadMyTeamPerformance", async(myData)=>{

  const token  = getToken()

   const response =  await axios({ 
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/Timelog/totalLoggedTimeWrapper`,
      headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
      data:  {performance_type:myData.performanceType}
      
    });
      
    const data = (await response).data; 

    removeToken(data.data); 
    return data;  

})
export const serachMyTeamPerformance = createAsyncThunk("myPerformace/serachMyTeamPerformance", async(myData)=>{

  const token  = getToken()

   const response =  await axios({ 
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
      headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
      data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,
        bill_non_bill_filter:myData.BillType, ...myData.reportToFilter, ...myData.eventullyReportsFilter, ...myData.excludeUserFilter}
      
    });
      
    const data = (await response).data; 
    removeToken(data.data); 
    return data;  

})

  // export const serachTeamPerformance = createAsyncThunk("myPerformace/serachPerformanceData", async(myData)=>{

  //   const token  = getToken()
  
  //    const response =  await axios({ 
  //       withCredentials: true,
  //       method: "post",
  //       url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
  //       headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
  //       data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,bill_non_bill_filter:myData.BillType}
        
  //     });
        
  //     const data = (await response).data; 
  //     removeToken(data.data); 
  //     return data;  
  
  // })

  export const getReportsToUsers = createAsyncThunk("myPerformace/getReportsToUsers", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Projects/teamPerformanceReportsTo`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        // data:  {performance_type:myData.performanceType}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const getEventuallyReportsToUsers = createAsyncThunk("myPerformace/teamPerformanceEventuallyReportsTo", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Projects/teamPerformanceEventuallyReportsTo`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        // data:  {performance_type:myData.performanceType}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });




const MyPerformanceSlice = createSlice({
    name: "myPerformace",
    initialState,
    reducers: {
       LOAD_DATA:(state,action) =>{
        //  state.userList = action.payload.data  
         state.loading = false
         state.userList = action.payload.data
      },
      CLEAR_PROGRESS_BAR:(state, action) =>{
        state.Productivity = null
        state.timelog = null
        state.searchTimelog= null
        state.myTeamtimelog = null
        state.myTeamsearchTimelog= null
        state.loading = null
      },
      REMOVE_TEAMPERFORMANCE_BY_USER: (state, action)=>{
        // console.log(action.payload);

         

        let arr = [];
         for(let i=0; i<action.payload.length; i++){
          arr.push(action?.payload[i]?.label);
         }
        
         if(state.myTeamtimelog !== null){
          let data =  [...state?.myTeamtimelog];
          let filterData = data[0]?.reportee?.filter(ele =>  !arr.includes(ele?.display_name))
          data[0].reportee = filterData;
          state.myTeamtimelog = data
         }else{
          let data =  [...state?.myTeamsearchTimelog];
          let filterData = data.filter(ele =>  !arr.includes(ele?.display_name))
          data = filterData;
          state.myTeamsearchTimelog = data
         }


      },
    

    },
    extraReducers: (builder)=>{
            
          builder.addCase(loadMyPerformance.pending, (state,action) => {
            state.loading = true
            state.timelog = null
            state.Productivity = null
              })
            builder.addCase(loadMyPerformance.fulfilled, (state, action) => {
            state.loading = false
            state.timelog = action.payload.data.timelog
            state.Productivity = action.payload.data.timelog
             })
            builder.addCase(loadMyPerformance.rejected, (state, action) => {
            state.loading = false
            state.timelog = null
            state.Productivity = null
             })  


             builder.addCase(serachMyPerformance.pending, (state,action) => {
             state.loading = true
             state.searchTimelog = null
             })
             builder.addCase(serachMyPerformance.fulfilled, (state, action) => {
             state.loading = false
             state.timelog = null
             state.searchTimelog = action.payload.data.timelog
             })
             builder.addCase(serachMyPerformance.rejected, (state, action) => {
             state.loading = false
             state.searchTimelog = null
             })  

             
             builder.addCase(loadMyTeamPerformance.pending, (state,action) => {
              state.loading = true
              state.myTeamtimelog = null
              state.final_records = null;
                })
              builder.addCase(loadMyTeamPerformance.fulfilled, (state, action) => {
              state.loading = false
              state.myTeamtimelog = action.payload.data.team_report
              state.final_records = action.payload.data.final_records  
               })
              builder.addCase(loadMyTeamPerformance.rejected, (state, action) => {
              state.loading = false
              state.myTeamtimelog = null
              state.final_records = null;
               })  
  
  
               builder.addCase(serachMyTeamPerformance.pending, (state,action) => {
               state.loading = true
               state.myTeamsearchTimelog = null
               state.final_records = null;
               })
               builder.addCase(serachMyTeamPerformance.fulfilled, (state, action) => {
               state.loading = false
               state.myTeamtimelog = null
               state.myTeamsearchTimelog = action.payload.data.team_report
               state.final_records = action.payload.data.final_records  
               })
               builder.addCase(serachMyTeamPerformance.rejected, (state, action) => {
               state.loading = false
               state.myTeamsearchTimelog = null
               state.final_records = null;
               })  
             
    }

});




export const {LOAD_DATA,CLEAR_PROGRESS_BAR,REMOVE_TEAMPERFORMANCE_BY_USER} = MyPerformanceSlice.actions;

export const myPrformancetimeLog = (state) => state.myPerformace.timelog
export const myPrformanceSearchtimeLog = (state) => state.myPerformace.searchTimelog
export const proDuctivity = (state) => state.myPerformace?.Productivity
export const myTeamSearchTimelog = (state) => state.myPerformace.myTeamsearchTimelog
export const myTeamTimelog = (state) => state.myPerformace.myTeamtimelog
export const get_final_records = (state) => state.myPerformace.final_records

export default MyPerformanceSlice.reducer