import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import cardData from "../Data/SingleProject";
import axios from "axios";
import { toast } from "react-toastify";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { API_BASE_URL } from "../utils/constants";
import { getToken } from "../utils/GlobalFunction";



const initialState = {
    Projects: cardData,
    Project_List: null,
    loading: false,
    message: null,
    error: null,
    result: null,
    singleProject: null,
    taskList: null,
    label: null,
    filterUser: null,
    project_data: null,
    user_details: null,
    project_id: null,
    task_list_ids : null,
    task_list_id: null,
    tasks_ids: null,
    task_id : null,
    FIlterTasks : null,
    tempTAsks : null,
    modalTaskId : null,
    userList : null,
    project_details: null
};

export const loadProjects = createAsyncThunk(
    "Project/ProjectList",
    async () => {
        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "get",
            url: `${API_BASE_URL}/Projects/index/`,
            headers: { Accesstoken: token },
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const delete_Project = createAsyncThunk(
    "Project/DeleteProject",
    async (id) => {
        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/deleteProject/${id}`,
            headers: { Accesstoken: token },
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        toast.success(data.message);
        return data;
    }
);

export const AddProjects = createAsyncThunk(
    "Project/AddProject",
    async (myData) => {
        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/doCreateProject`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: myData,
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        // toast.error(data.result === -1 ? data.msg : null);
        return data;
    }
);

export const getSingleProject = createAsyncThunk(
    "Project/getSingleProject",
    async (myData) => {
        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "get",
            url: `${API_BASE_URL}/Projects/getProjectDetailsById/${myData}`,
            headers: { Accesstoken: token },
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const UpdateProjects = createAsyncThunk(
    "Project/UpdateProject",
    async (myData) => {
        const token = getToken()

   
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/doUpdateProject/${myData.id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: myData.EditData,
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
       
        return data;
    }
);

export const viewProjectGrid = createAsyncThunk("Project/viewProjectGrid",
    async (myData) => {
        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "get",
            url: `${API_BASE_URL}/Projects/viewProjectGrid/${myData}`,
            headers: { Accesstoken: token },
        });
   
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const drag_and_drop_task_list = createAsyncThunk("Project/drag_and_drop_task_list", async (mydata) => {
        const token = getToken()
        
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskListSeq`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data"},
            data : mydata.myobj
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        // toast.success(data.message);
        return data;
    }
);


export const drag_and_drop_tasks = createAsyncThunk("Project/deag_and_drop_tasks", async (mydata) => {
    const token = getToken()
       
    const response = await axios({
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/updateTaskSequence`,
        headers: { Accesstoken: token, "Content-Type": "multipart/form-data"},
        data : mydata.myobj
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    // toast.success(data.message);
    return data;
}
);


// pending filter task
export const filterTaskByUser = createAsyncThunk(
    "Project/viewProjectGridFilter1",
    async (myData) => {
   
        const token = getToken()
       
        const myobj = {};
        Object.assign(myobj, ...myData.uid);
       

        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/gridViewFilter/${myData.pid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: myobj,
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const filterTaskByCruuntUser = createAsyncThunk(
    "Project/viewProjectGridFilter",
    async (myData) => {
        const token = getToken()

        // console.log(myData);
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/gridViewFilter/${myData.pid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {user_id:myData.user_id}
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const AddTask = createAsyncThunk("Project/AddTask", async (myData) => {
    const token = getToken()

    const myObj = {
        task_name: myData.cardTitle,
        owner: myData.id,
        user_id: myData.id,
    };

    const response = await axios({
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/addNewTaskGrid/${myData.bid}/${myData.project_id}`,
        headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
        data: myObj,
    });
  
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
});

export const AddSubTasks = createAsyncThunk(
    "Project/AddSubTask",
    async (myData) => {
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/insertTaskChecklist/${myData.cid}/${myData.pid}/${myData.bid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_name: myData.subTaskTitle },
        });
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const UpdateSubTasks = createAsyncThunk(
    "Project/UpdateSubTasks",
    async (myData) => {
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/insertTaskChecklist/${myData.cid}/${myData.pid}/${myData.bid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_name: myData.subTaskTitle, checklist_id: myData.tid },
        });
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateTaskName = createAsyncThunk(
    "Project/UpdateTaskName",
    async (myData) => {
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskName/${myData.cid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_name: myData.taskTitle },
        });
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const DeleteTask = createAsyncThunk(
    "Project/DeleteTask",
    async (myData) => {
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/deleteTask/${myData}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_type: "task" },
        });
       
          const data = (await response).data; 
          removeToken(data.status_code);
          sessionTimeoutCheck(response.data) 
          return data
    }
);

export const DeleteSubTask = createAsyncThunk(
    "Project/DeleteSubTask",
    async (myData) => {
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/deleteTaskChecklist/${myData.tid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_id: myData.cid },
        });
        
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const UpdateTaskListName = createAsyncThunk(
    "Project/UpdateTaskListName",
    async (myData) => {
     
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/doUpdateTaskList/${myData.pid}/${myData.bid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { task_list: myData.TaskListTitle },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateTaskBucket = createAsyncThunk(
    "Project/updateTaskBucket",
    async (myData) => {
      
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskBucket/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { bucket: myData.bid, type: "task" },
        });

        const data = (await response).data;
        removeToken(data.status_code);
        return data;
    }
);

export const updateTaskPriority = createAsyncThunk(
    "Project/updateTaskPriority",
    async (myData) => {
    
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskPriority/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { priority: myData.priority, type: "task" },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateTaskStatus = createAsyncThunk(
    "Project/updateTaskStatus",
    async (myData) => {
      
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskStatus/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { status: myData.progress, type: "task" },
        });
       
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateStartDueDate = createAsyncThunk(
    "Project/updateStartDueDate",
    async (myData) => {
      
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateStartDueDate/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                start_date: myData?.startDate,
                due_date: myData.endDate,
                task_type: "task",
            },
        });
      
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const doUpdateNotes = createAsyncThunk(
    "Project/doUpdateNotes",
    async (myData) => {
    
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/doUpdateNotes/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                notes: myData.notes,
                task_type: "task",
            },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateTaskLabel = createAsyncThunk(
    "Project/updateTaskLabel",
    async (myData) => {
   
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskLabel/${myData.cid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                label_id: myData.id,
            },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const unassignTask = createAsyncThunk(
    "Project/unassignTask",
    async (myData) => {
      
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/unassignTask/${myData.task_id}/${myData.uid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                task_owner_id: myData.taid,
                type: "task",
            },
        });
      
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateGridTaskOwner = createAsyncThunk(
    "Project/updateGridTaskOwner",
    async (myData) => {
    
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateGridTaskOwner/${myData.task_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                owner: myData.uid,
                type: myData.taskType,
            },
        });

        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updatesubTaskOwner = createAsyncThunk(
    "Project/updatesubTaskOwner",
    async (myData) => {
       
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateTaskOwner/${myData.subtask_id}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                owner: myData.uid,
                type: 'sub_task',
            },
        });
  
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const AddTaskWrapper = createAsyncThunk(
    "Project/AddTask",
    async (myData) => {
     
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/addNewTaskListWrapper/${myData.pid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: { user_id: myData.uid, task_name: "Untitled Card 1" },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const showToCard = createAsyncThunk(
    "Project/showToCard",
    async (myData) => {
        
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/showOnCard/${myData.cid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                show_card: myData.status,
                task_id: myData.cid,
                user_id: myData.uid,
            },
        });
 
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const updateTaskStatusComplete = createAsyncThunk(
    "Project/updateTaskStatusComplete",
    async (myData) => {
        
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/updateStatusCompleted/${myData.tid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
            data: {
                status: myData.status,
                type: "sub-task",
                grid_checklist: "yes",
                user_id: myData.uid,
            },
        });
  
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const deleteTaskList = createAsyncThunk(
    "Project/deleteTaskList",
    async (myData) => {

        const token = getToken()

        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Task/deleteTaskList/${myData.bid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data" },
        });
    
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const AddToFevProject = createAsyncThunk(
    "Project/AddToFevProject",
    async (myData) => {

        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "post",
            url: `${API_BASE_URL}/Projects/addProjectToFavorites/${myData.pid}/${myData.uid}`,
            headers: { Accesstoken: token, "Content-Type": "multipart/form-data"},
            
        });
  
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const DisplayTaskModal = createAsyncThunk(
    "Project/taskModal",
    async (myData) => {
    
        const token = getToken()
        const response = await axios({
            withCredentials: true,
            method: "get",
            url: `${API_BASE_URL}/Projects/taskModal/${myData.tid}`,
            headers: { Accesstoken: token},
            
        });
  
        const data = (await response).data;
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data)
        return data;
    }
);

export const getUserList = createAsyncThunk("/Projects/getUsersList", async()=>{

    const token  = getToken();
      
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Projects/getUsersList`,
        headers:{'Accesstoken':token}
        });
    
      const data = (await response).data;  
      removeToken(data.status_code);
      sessionTimeoutCheck(response.data);
      return data;  
  
  });

export const SyncTasks = createAsyncThunk("/Projects/SyncTasks", async(myData)=>{

    const token  = getToken();
      
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/syncData/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data : {user_id: myData.user_id, label_id: myData.label_id}
        });
    
      const data = (await response).data;  
      removeToken(data.status_code);
      sessionTimeoutCheck(response.data);

        return data;
      
        
  
  });



//   fix project
export const fixedProjects = createAsyncThunk("/Projects/viewProjectReportWrapper/Fixed", async(myData)=>{

    const token  = getToken();
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Projects/viewProjectReportWrapper/Fixed`,
        headers: { Accesstoken: token, "Content-Type": "multipart/form-data"},
        data : {'date': myData.date}
        });
      const data = (await response).data;  
      removeToken(data.status_code);
      sessionTimeoutCheck(response.data);
      return data;
  });

  //   recurringProjects
export const recurringProjects = createAsyncThunk("/Projects/viewProjectReportWrapper/Recurring", async(myData)=>{

    const token  = getToken();
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Projects/viewProjectReportWrapper/Recurring`,
        headers: { Accesstoken: token, "Content-Type": "multipart/form-data"},
        data : {month: myData.date}
        });
      const data = (await response).data;  
      removeToken(data.status_code);
      sessionTimeoutCheck(response.data);
        return data;
  });



  


const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        SET_PROJECT: (state, action) => {
            state.taskList.push({
                task_list_id: action.payload.bid,
                task_list_name: "Untitled Card 1",
                project_id: action.payload.pid,
                task_data: [],
            });
        },
        REMOVE_TASKLIST: (state, action) => {
            const index = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            state.taskList.splice(index, 1);
        },
        SET_TASK_CARD: (state, action) => {
            const task = {
                task_id: action.payload.card.id,
                task_name: action.payload.card.task_name,
                task_status: null,
                start_date: null,
                due_date: null,
                show_card: null,
                completed_by: null,
                completed_user_name: null,
                task_assigned_users: [
                    {
                        task_assigned_id: action.payload.card.task_owners[0].id,
                        task_assigned_user_id: action.payload.card.task_owners[0].user_id,
                        task_id: action.payload.card.id,
                        task_assigned_user_name: action.payload.card.task_owners[0].name,
                    },
                ],
                task_labels_data: [],
                sub_task_data: [],
            };
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempBoard = [...state.taskList];
            tempBoard[boardIndex].task_data.unshift(task);
        },
        SET_SUBTASK: (state, action) => {
            const subtask = {
                sub_task_id: 0,
            };
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.cid
            );
        
            if (
                tempboard[boardIndex].task_data[cardIndex].sub_task_data.findIndex(
                    (ele) => ele.sub_task_id === 0
                ) === -1
            ) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data.push(subtask);
            }
        },
        ADD_TO_FEV: (state, action) =>{
            const tempProject = [...state.Project_List]
            const projectIndex=    state.Project_List.findIndex(ele => ele.project_id === action.payload.pid)
            if(tempProject[projectIndex].favorite === "0"){
                tempProject[projectIndex].favorite = "1"
            }else{
                tempProject[projectIndex].favorite  = "0"
            }
           
        },
        REMOVE_SUBTASK_FIELD: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.cid
            );
            const subtaskIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex((ele) => ele.sub_task_id === 0);
            if (subtaskIndex !== -1) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data.splice(
                    subtaskIndex,
                    1
                );
            }
        },
        FILTER_BY_LABEL: (state, action) => {
            const { id, isChecked,type } = action.payload;
            if(type === "label"){
                let filteredTasks = [];
                if (isChecked && id.length > 0) {
                  // If labels are checked, filter tasks based on labels
                 
                 
                  filteredTasks = state.taskList?.flatMap(item => ({
                    ...item,
                    task_data: item.task_data.filter(task => 
                      task.task_labels_data.find(label => id.includes(label.task_label_id))
                    )
                  }));
                 
                 
                    state.FIlterTasks = filteredTasks;
                  
                //   console.log(JSON.parse(JSON.stringify({check: filteredTasks})));
                  
                  
             
                } else if(id.length === 0){
                    state.FIlterTasks = null
                } else {
                  // If labels are unchecked, filter tasks based on labels
                    
                  filteredTasks = state.FIlterTasks?.flatMap(item => ({
                    ...item,
                    task_data: item.task_data.filter(task =>
                      task.task_labels_data.find(label => id.includes(label.task_label_id))
                    )
                  }));

               
                    state.FIlterTasks = filteredTasks;
                }
                
                
                
            }
           
            if(type === "user"){      
                let filterTasksByUser = [];
                if (isChecked && id.length > 0) {

                    filterTasksByUser = state.taskList?.flatMap(item => ({
                        ...item,
                        task_data: item.task_data.filter(task => 
                          task.task_assigned_users?.find(label => id.includes(label.task_assigned_user_id))
                        )
                      }));
                      state.FIlterTasks = filterTasksByUser;
                    
                }else{
                    filterTasksByUser = state.FIlterTasks?.flatMap(item => ({
                        ...item,
                        task_data: item.task_data.filter(task =>
                          task.task_assigned_users?.find(label => id.includes(label.task_assigned_user_id))
                        )
                      }));
                      state.FIlterTasks = filterTasksByUser;
                         
                }
            }
     

          },
        CLEAR_LABEL_FILTER:(state,action)=>{
            state.FIlterTasks = null
          },
        UPDATE_SUBTASK: (state, action) => {
            const boardIndex = state.taskList.findIndex((ele) => ele.task_list_id === action.payload.bid);
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex((ele) => ele.task_id === action.payload.cid);
            tempboard[boardIndex].task_data[cardIndex].sub_task_data = [];
            tempboard[boardIndex].task_data[cardIndex].sub_task_data.push(
                ...action.payload.subtask
            );
        },
        UPDATE_SUB_TASK_TEXT: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.cid
            );
            const subtaskIndexDummy = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex((ele) => ele?.id === action.payload.tid);
            const subtaskIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex(
                (ele) => ele?.sub_task_id === action.payload.tid
            );

            if (subtaskIndexDummy !== -1) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data[
                    subtaskIndexDummy
                ].task_name = action.payload.subTaskTitle;
            }

            if (subtaskIndex !== -1) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data[
                    subtaskIndex
                ].sub_task_name = action.payload.subTaskTitle;
            }
        },
        REMOVE_SUB_TASKS: (state, action) => {
            //pending
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.cid
            );

            const subtaskIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex(
                (ele) => ele.sub_task_id === action.payload.tid
            );
            const dummySubTaskIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex(
                (ele) => ele.id === action.payload.tid
            );

            if (subtaskIndex !== -1) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data.splice(
                    subtaskIndex,
                    1
                );
            }

            if (dummySubTaskIndex !== -1) {
                tempboard[boardIndex].task_data[cardIndex].sub_task_data.splice(
                    dummySubTaskIndex,
                    1
                );
            }


        },
        SET_TASK_CARD_TITLE: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).task_name = action.payload.taskTitle;
        },
        SET_BOARD_TITLE: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_list_name = action.payload.title;
        },
        ADD_LABEL: (state, action) => {
            // var data;
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.boardId
            );
            const tempboard = [...state.taskList];

            const cardIndex = state.taskList
                .find((ele) => ele.task_list_id === action.payload.boardId)
                .task_data.findIndex((item) => item.task_id === action.payload.cid);

            const labelIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].task_labels_data.findIndex(
                (ele) => ele.task_label_id === action.payload.item.id
            );

            if (
                tempboard[boardIndex].task_data[cardIndex].task_labels_data.find(
                    (ele) => ele.task_label_id === action.payload.item.id
                )
            ) {
                tempboard[boardIndex].task_data[cardIndex].task_labels_data.splice(
                    labelIndex,
                    1
                );
            } else {
                tempboard[boardIndex].task_data[cardIndex].task_labels_data.push({
                    task_label_id: action.payload.item.id,
                    task_label_name: action.payload.item.label,
                    task_label_color_code: action.payload.item.color_code,
                    status: 0,
                });
            }
        },
        SET_DUE_DATE: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).due_date = action.payload.date;
        },
        SET_START_DATE: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).start_date = action.payload.date;
        },
        UPDATE_TASK_NOTE: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).task_notes = action.payload.notes;
        },
        UPDATE_TASK_PROGRESS: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).task_status = action.payload.progress;
        },
        UPDATE_TASK_PRIORITY: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            tempboard[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).task_priority = action.payload.priority;
        },
        //pending
        ASSIGN_USER: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.boardId
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.task_id
            );

            const assignUserIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].task_assigned_users.findIndex(
                (ele) => ele.task_assigned_user_id === action.payload.uid
            );

            if (
                tempboard[boardIndex].task_data[cardIndex].task_assigned_users.find(
                    (ele) => ele.task_assigned_user_id === action.payload.uid
                )
            ) {
                tempboard[boardIndex].task_data[cardIndex].task_assigned_users.splice(
                    assignUserIndex,
                    1
                );
            } else {
                tempboard[boardIndex].task_data[cardIndex].task_assigned_users.push({
                    //pending task assign id
                    // "task_assigned_id": "",
                    task_assigned_user_id: action.payload.uid,
                    task_id: action.payload.task_id,
                    task_assigned_user_name: action.payload.name,
                });
            }
        },
        SET_COMPLETE_TASK: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const board = [...state.taskList];
            const cardIndex = board[boardIndex].task_data.findIndex(
                (ele) => ele.task_id === action.payload.cid
            );
            board[boardIndex].task_data[cardIndex].task_status =
                action.payload.status;
            board[boardIndex].task_data[cardIndex].completed_user_name =
                action.payload.userName;
        },
        SET_INCOMPLETE_TASK: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const board = [...state.taskList];
            board[boardIndex].task_data.find(
                (ele) => ele.task_id === action.payload.cid
            ).task_status = action.payload.status;
        },
        DRAG_AND_DROP_CARD: (state, action) => {
            if (action.payload.source.droppableId !==action.payload.destination.droppableId) {
                const sourceColInd = state.taskList.findIndex(
                    (ele) => ele.task_list_id === action.payload.source.droppableId
                );
                const destinationColInd = state.taskList.findIndex(
                    (ele) => ele.task_list_id === action.payload.destination.droppableId
                );

                const sourceCol = state.taskList[sourceColInd];
                const destinationCol = state.taskList[destinationColInd];

                const sourceCard = [...sourceCol.task_data];
                const destinationCard = [...destinationCol.task_data];

                const [removed] = sourceCard.splice(action.payload.source.index, 1);
                destinationCard.splice(action.payload.destination.index, 0, removed);
                
                //store destination task ids for api
                 state.task_list_id = action.payload.destination.droppableId;
                 state.tasks_ids =  JSON.parse(JSON.stringify(destinationCard));
                 state.task_id = action.payload.result.draggableId   

                

                // api call
                
                state.taskList[sourceColInd].task_data = sourceCard;
                state.taskList[destinationColInd].task_data = destinationCard;

                

                  
            }

            if(action.payload.type === "task" && action.payload.source.droppableId === action.payload.destination.droppableId) {
                const columnIndex = state.taskList.findIndex(
                    (item) => item.task_list_id === action.payload.S_dropableID
                );
                const [removed] = state.taskList[columnIndex].task_data.splice(
                    action.payload.source.index,
                    1
                );
              

                  state.taskList[columnIndex].task_data.splice(
                    action.payload.destination.index,
                    0,
                    removed
                );
  
                const tasksIds = state.taskList.find(
                    (item) => item.task_list_id === action.payload.S_dropableID
                )?.task_data;

             
               
                state.task_list_id = action.payload.destination.droppableId;
                state.tasks_ids =  JSON.parse(JSON.stringify(tasksIds));
                state.task_id = action.payload.result.draggableId  
               


            }
            if (action.payload.type === "column" && action.payload.source.droppableId === action.payload.destination.droppableId) {
          
                const [removed] = state.taskList.splice(action.payload.source.index, 1);
                state.taskList.splice(action.payload.destination.index, 0, removed);
                //pass updated list
                state.task_list_ids = state.taskList;
            }
        },
        CLEAR_TASK_LIST_ID: (state, action) => {
            state.task_list_ids = null;
        },
        CLEAR_TASKS_DRAG: (state, action) => {
            state.task_list_id = null
            state.tasks_ids =  null           
            state.task_id = null
        },
        UPDATE_SUB_TASK_STATUS: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];

            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (item) => item.task_id === action.payload.cid
            );
            const TaskIndex = tempboard[boardIndex].task_data[
                cardIndex
            ].sub_task_data.findIndex(
                (ele) => ele.sub_task_id === action.payload.tid
            );
            tempboard[boardIndex].task_data[cardIndex].sub_task_data[
                TaskIndex
            ].sub_task_status = action.payload.status;
        },
        SHOW_CARD: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempboard = [...state.taskList];
            const cardIndex = tempboard[boardIndex].task_data.findIndex(
                (item) => item.task_id === action.payload.cid
            );
            tempboard[boardIndex].task_data[cardIndex].show_card =
                action.payload.status;
        },

        REMOVE_TASK_CARD: (state, action) => {
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.bid
            );
            const tempBoard = [...state.taskList];
            tempBoard[boardIndex].task_data.splice(
                tempBoard[boardIndex].task_data.findIndex(
                    (ele) => ele.task_id === action.payload.cid
                ),
                1
            );
        },

        SET_SUBTASK_TITLE: (state, action) => {
            const boardIndex = state.Projects[0].projectTasks.findIndex(
                (ele) => ele.id === action.payload.bid
            );
            const tempboard = [...state.Projects[0].projectTasks];
            tempboard[boardIndex].cards
                .find((ele) => ele.id === action.payload.cid)
                .tasks.find((item) => item.id === action.payload.tid).title =
                action.payload.title;
        },

        SET_CARD_TASK_INFO: (state, action) => {
            //pending
            const boardIndex = state.Projects[0].projectTasks.findIndex(
                (item) => item.id === action.payload.bid
            );
            const tempBoards = [...state.Projects[0].projectTasks];
            tempBoards[boardIndex].cards.find(
                (ele) => ele.id === action.payload.cid
            ).endDate = action.payload.endDate;
            tempBoards[boardIndex].cards.find(
                (ele) => ele.id === action.payload.cid
            ).startDate = action.payload.startDate;
            tempBoards[boardIndex].cards.find(
                (ele) => ele.id === action.payload.cid
            ).note = action.payload.note;
            tempBoards[boardIndex].cards.find(
                (ele) => ele.id === action.payload.cid
            ).priority = action.payload.priority;
            tempBoards[boardIndex].cards.find(
                (ele) => ele.id === action.payload.cid
            ).progress = action.payload.progress;
        },

        ADD_LOG_TIME_SUBTASK: (state, action) => {
    
            const boardIndex = state.Projects[0].projectTasks.findIndex(
                (ele) => ele.id === action.payload.bid
            );
            const tempboard = [...state.Projects[0].projectTasks];

            const cardIndex = state.Projects[0].projectTasks
                .find((ele) => ele.id === action.payload.bid)
                .cards.findIndex((item) => item.id === action.payload.cid);
            const taskIndex = tempboard[boardIndex].cards[cardIndex].tasks.findIndex(
                (ele) => ele.id === action.payload.tid
            );
            tempboard[boardIndex].cards[cardIndex].tasks[taskIndex].timelog.push(
                action.payload
            );
        },

        REMOVE_TIMELOG: (state, action) => {
            const boardIndex = state.Projects[0].projectTasks.findIndex(
                (ele) => ele.id === action.payload.bid
            );
            const tempboard = [...state.Projects[0].projectTasks];

            const cardIndex = state.Projects[0].projectTasks
                .find((ele) => ele.id === action.payload.bid)
                .cards.findIndex((item) => item.id === action.payload.cid);
            const TaskIndex = tempboard[boardIndex].cards[cardIndex].tasks.findIndex(
                (ele) => ele.id === action.payload.tid
            );
            const TimelogIndex = tempboard[boardIndex].cards[cardIndex].tasks[
                TaskIndex
            ].timelog.findIndex((ele) => ele.id === action.payload.lid);

            tempboard[boardIndex].cards[cardIndex].tasks[TaskIndex].timelog.splice(
                tempboard[boardIndex].cards[cardIndex].tasks[TaskIndex].timelog[
                TimelogIndex
                ],
                1
            );
        },
        CLEAR_RESULT: (state, action) => {
            state.result = null;
        },
        STORE_TASK: (state, action) => {
            // state.taskList = action.payload.data
        },
        clearTaskList: (state, action) => {
            state.taskList = null;
        },
        ASSIGN_SUBTASK_USERS:(state, action)=>{
            const boardIndex = state.taskList.findIndex(
                (ele) => ele.task_list_id === action.payload.boardId
            );
            // console.log(boardIndex);
            const tempBoard = [...state.taskList]
            const taskIndex = tempBoard[boardIndex].task_data.findIndex(ele => ele.task_id === action.payload?.task_id);
            
             const subtaskIndex = tempBoard[boardIndex].task_data[taskIndex]?.sub_task_data.findIndex(ele => ele?.id || ele?.sub_task_id  === action.payload.subtask_id)


             
            //  console.log(subtaskIndex);
            // console.log(JSON.parse(JSON.stringify({subtaskData: tempBoard[boardIndex].task_data[taskIndex]?.sub_task_data})));
            // console.log({payload_subtask_id: action.payload.subtask_id});
            if(tempBoard[boardIndex].task_data[taskIndex].sub_task_data[subtaskIndex].sub_task_assigned_user_id !== action.payload.uid){
                tempBoard[boardIndex].task_data[taskIndex].sub_task_data[subtaskIndex].sub_task_assigned_user_id = action.payload.uid
                tempBoard[boardIndex].task_data[taskIndex].sub_task_data[subtaskIndex].sub_task_assigned_user_name = action.payload.name

            }

        },
        SET_MODAL_TASK_ID: (state, action) =>{
            state.modalTaskId = action.payload.cid
        },
        CLEAR_SINGLE_PROJECT: (state,action)=>{
            state.singleProject = null;
        },
        CLEAR_CURRUNT_PROJECT: (state,action)=>{
            state.loading = null
            state.singleProject= null
            state.taskList= null
            state.label= null
            state.filterUser= null
            state.project_data= null
            state.user_details= null
            state.project_id= null
            state.task_list_ids= null
            state.task_list_id= null
            state.tasks_ids= null
            state.task_id = null
            state.FIlterTasks = null
            state.tempTAsks = null
            state.modalTaskId = null

        },
        CLEAR_ERROR_PROJECT: (state,action)=>{
            state.error = null
        },
        
        UPDATECOLUMNORDER: (state,action) =>{
            state.taskList = action.payload.updated
        },
        UPDATECARDINSAMECOLUMN : (state, action) =>{
            state.taskList = action.payload.updatedData
        },
        UPDATECARDINOTHERCOLUMN: (state, action)=>{
            state.taskList = action.payload.updatedData 
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProjects.pending, (state, action) => {
                state.loading = true;
                state.Project_List = null;
                // state.error = null;
                // state.message = null;
            })
            .addCase(loadProjects.fulfilled, (state, action) => {
                const ListWithId = action.payload?.data.map((row,index)=>({
                    cid: index+1,
                    ...row
                  }))
                state.loading = false;
                state.Project_List = ListWithId;
                // state.error = null;
                // state.message = null;
            })
            .addCase(loadProjects.rejected, (state, action) => {
                state.loading = false;
                state.Project_List = null;
                // state.error = null;
                // state.message = null;
            })

            //delete user
            // .addCase(delete_Project.pending, (state, action) => {
            //     // state.loading = true;
            //     // state.error = null;
            //     // state.message = null;
            //     state.Project_List = null;
            // })
            // .addCase(delete_Project.fulfilled, (state, action) => {
            //     // state.loading = false;
            //     // state.error = null;
            //     // state.message = null;
            //     const ListWithId = action.payload?.data.map((row,index)=>({
            //         cid: index+1,
            //         ...row
            //       }))
            //     state.Project_List = ListWithId
            // })
            // .addCase(delete_Project.rejected, (state, action) => {
            //     // state.loading = false;
            //     state.Project_List = null;
            //     // state.error = null;
            //     // state.message = null;
            // })

            //Add user
            .addCase(AddProjects.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.message = null;
                state.result = null;
            })
            .addCase(AddProjects.fulfilled, (state, action) => {
                state.loading = false;
                state.error = action.payload.errors;
                state.message = action.payload.msg;
                state.result = action.payload.status_code;
            })
            .addCase(AddProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = null;
                state.message = null;
                state.result = null;
            })

            //Add user
            .addCase(getSingleProject.pending, (state, action) => {
                state.loading = true;
                state.singleProject = null;
            })
            .addCase(getSingleProject.fulfilled, (state, action) => {
                state.loading = false;
                state.singleProject = action.payload.data;
            })
            .addCase(getSingleProject.rejected, (state, action) => {
                state.loading = false;
                state.singleProject = null;
            })

            .addCase(UpdateProjects.pending, (state, action) => {
                // state.loading = true;
                state.error = null;
                state.message = null;
                state.result = null;
            })
            .addCase(UpdateProjects.fulfilled, (state, action) => {
                // state.loading = false;
                state.error = action.payload.errors;
                state.message = action.payload.msg;
                state.result = action.payload.status_code;
            })
            .addCase(UpdateProjects.rejected, (state, action) => {
                // state.loading = false;
                state.error = null;
                state.message = null;
                state.result = null;
            })

            .addCase(viewProjectGrid.pending, (state, action) => {
                state.loading = true;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
                state.project_details = null;
            })
            .addCase(viewProjectGrid.fulfilled, (state, action) => {
                const tasklist = Object.values(action.payload?.data?.task_list)?.map(
                    (ele) => ({
                        ...ele,
                        task_data: Object.values(ele?.task_data || [])
                            // .reverse()
                            .map((task) => ({
                                ...task,
                                task_labels_data: Object.values(task?.task_labels_data || []),
                                sub_task_data: Object.values(task?.sub_task_data || []),
                            })).sort((a, b) => {
                                // Move completed tasks to the end
                                if (a?.task_status === 'Completed' && b?.task_status !== 'Completed') {
                                    return 1;
                                } else if (a?.task_status !== 'Completed' && b?.task_status === 'Completed') {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }),
                    })
                );
          
                
                state.loading = false;
                state.taskList = tasklist;
                state.label = action.payload.data.label;
                state.filterUser = action.payload.data.owner_list;
                state.project_data = action.payload.data;
                state.user_details = action.payload.data.user_detail;
                state.project_id = action.payload.data.project_id;
                state.project_details = action.payload?.data?.projects;
            })
            .addCase(viewProjectGrid.rejected, (state, action) => {
                state.loading = false;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
                state.project_details = null;
            })

            .addCase(filterTaskByUser.pending, (state, action) => {
                state.loading = true;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
            })
            .addCase(filterTaskByUser.fulfilled, (state, action) => {
                const tasklist = Object.values(action.payload?.data.task_list).map(
                    (ele) => ({
                        ...ele,
                        task_data: Object.values(ele.task_data || [])
                            // .reverse()
                            .map((task) => ({
                                ...task,
                                task_labels_data: Object.values(task.task_labels_data || []),
                                sub_task_data: Object.values(task.sub_task_data || []),
                            })),
                    })
                );

                state.loading = false;
                state.taskList = tasklist;
                state.label = action.payload.data.label;
                // state.filterUser = action.payload.data.owner_list;
                state.project_data = action.payload.data;
                state.user_details = action.payload.data.user_detail;
                state.project_id = action.payload.data.project_id;
            })
            .addCase(filterTaskByUser.rejected, (state, action) => {
                state.loading = false;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
            })

            .addCase(filterTaskByCruuntUser.pending, (state, action) => {
                state.loading = true;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
            })
            .addCase(filterTaskByCruuntUser.fulfilled, (state, action) => {
                const tasklist = Object.values(action.payload?.data.task_list).map(
                    (ele) => ({
                        ...ele,
                        task_data: Object.values(ele.task_data || [])
                            // .reverse()
                            .map((task) => ({
                                ...task,
                                task_labels_data: Object.values(task.task_labels_data || []),
                                sub_task_data: Object.values(task.sub_task_data || []),
                            })).sort((a, b) => {
                                // Move completed tasks to the end
                                if (a.task_status === 'Completed' && b.task_status !== 'Completed') {
                                    return 1;
                                } else if (a.task_status !== 'Completed' && b.task_status === 'Completed') {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }),
                    })
                );
                // console.log(tasklist);    
                state.loading = false;
                state.taskList = tasklist;
                state.label = action.payload.data.label;
                state.filterUser = action.payload.data.owner_list;
                state.project_data = action.payload.data;
                state.user_details = action.payload.data.user_detail;
                state.project_id = action.payload.data.project_id;
            })
            .addCase(filterTaskByCruuntUser.rejected, (state, action) => {
                state.loading = false;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
            })

            .addCase(SyncTasks.pending, (state, action) => {
                // state.loading = true;
                // state.taskList = null;
                // state.label = null;
                // state.filterUser = null;
                // state.project_data = null;
                // state.user_details = null;
                // state.project_id = null;
            
            })
            .addCase(SyncTasks.fulfilled, (state, action) => {
                    if(action.payload.data){

                        state.loading = true;
                        state.taskList = null;
                        state.label = null;
                        state.filterUser = null;
                        state.project_data = null;
                        state.user_details = null;
                        state.project_id = null;


                        const tasklist = Object.values(action.payload.data.task_list).map(
                            (ele) => ({
                                ...ele,
                                task_data: Object.values(ele.task_data || [])
                                    // .reverse()
                                    .map((task) => ({
                                        ...task,
                                        task_labels_data: Object.values(task.task_labels_data || []),
                                        sub_task_data: Object.values(task.sub_task_data || []),
                                    })),
                            })
                        );
                        
                        state.loading = false;
                        state.taskList = tasklist;
                        state.label = action.payload.data.label;
                        state.filterUser = action.payload.data.owner_list;
                        state.project_data = action.payload.data;
                        state.user_details = action.payload.data.user_detail;
                        state.project_id = action.payload.data.project_id;
                    }

            })
            .addCase(SyncTasks.rejected, (state, action) => {
                state.loading = false;
                state.taskList = null;
                state.label = null;
                state.filterUser = null;
                state.project_data = null;
                state.user_details = null;
                state.project_id = null;
            })


            //add to fev
            .addCase(AddToFevProject.pending, (state, action) => {
                // state.loading = true;
                state.Project_List = null;
                // state.error = null;
                // state.message = null;
            })
            .addCase(AddToFevProject.fulfilled, (state, action) => {
                const ListWithId = action.payload?.data.map((row,index)=>({
                    cid: index+1,
                    ...row
                  }))
                // state.loading = false;
                state.Project_List = ListWithId;
                // state.error = null;
                // state.message = null;
            })
            .addCase(AddToFevProject.rejected, (state, action) => {
                state.Project_List = null;
            })

            .addCase(getUserList.pending, (state, action) => {
                state.userList = null;
            })

            .addCase(getUserList.fulfilled, (state, action) => {
              
                  state.userList = action.payload?.data.owner_list;
            })
            .addCase(getUserList.rejected, (state, action) => {
                state.userList = null;
            })
            

    },
});

export const {
    CLEAR_RESULT,
    SET_PROJECT,
    SET_TASK_CARD,
    DRAG_AND_DROP_CARD,
    REMOVE_TASK_CARD,
    SET_DUE_DATE,
    SET_COMPLETE_TASK,
    SET_INCOMPLETE_TASK,
    SET_BOARD_TITLE,
    SET_SUBTASK,
    SET_SUBTASK_TITLE,
    SET_TASK_CARD_TITLE,
    SET_CARD_TASK_INFO,
    REMOVE_SUB_TASKS,
    ADD_LOG_TIME_SUBTASK,
    ADD_LABEL,
    UPDATE_SUB_TASK_STATUS,
    REMOVE_TIMELOG,
    STORE_TASK,
    clearTaskList,
    UPDATE_SUBTASK,
    ASSIGN_USER,
    SET_START_DATE,
    SHOW_CARD,
    UPDATE_TASK_NOTE,
    UPDATE_TASK_PROGRESS,
    UPDATE_TASK_PRIORITY,
    REMOVE_SUBTASK_FIELD,
    REMOVE_TASKLIST,
    UPDATE_SUB_TASK_TEXT,
    CLEAR_TASK_LIST_ID,
    FILTER_BY_LABEL,
    CLEAR_TASKS_DRAG,
    CLEAR_LABEL_FILTER,
    ADD_TO_FEV,
    ASSIGN_SUBTASK_USERS,
    SET_MODAL_TASK_ID,
    CLEAR_SINGLE_PROJECT,
    CLEAR_CURRUNT_PROJECT,
    CLEAR_ERROR_PROJECT,
    UPDATECOLUMNORDER,
    UPDATECARDINSAMECOLUMN,
    UPDATECARDINOTHERCOLUMN
} = projectSlice.actions;

export const getProject = (state) => state.project.Projects[0].projectTasks;
export const get_Project_List = (state) => state.project.Project_List;
export const project_loading = (state) => state.project.loading;
export const project_message = (state) => state.project.message;
export const project_errors = (state) => state.project.error;
export const project_result = (state) => state.project.result;

export const taskLists = (state) => state.project.taskList;

export const Labels = (state) => state.project.label;
export const UserFilterList = (state) => state.project.filterUser;
export const ProjectTaskData = (state) => state.project.project_data;
export const userDetails = (state) => state.project.user_details;
export const projectId = (state) => state.project.project_id;

export const taskListIds = (state) => state.project.task_list_ids;
export const taskListId = (state) => state.project.task_list_id;
export const tasksIds = (state) => state.project.tasks_ids;
export const task_ID = (state) => state.project.task_id
export const filterTaskList = (state) => state.project.FIlterTasks


export const getTask = (state) => state.project.Projects[0].projectTasks;
export const single_project_data = (state) => state.project.singleProject;

export const modal_task_id = (state) => state.project.modalTaskId;
export const userslist = (state) => state.project.userList;

export const projectDetails = (state) => state.project.project_details;
export default projectSlice.reducer;
