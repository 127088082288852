import React from 'react'
import './Loader.scss';
import ReactDOM from 'react-dom';

const Loader = () => {
  return ReactDOM.createPortal (
    <div className='Loader'>
      <span className='loader'></span>
    </div>,
    document.getElementById("loader")
  )
}

export default Loader