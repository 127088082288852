import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.scss';
import React,{ Suspense} from 'react';
import 'react-toastify/dist/ReactToastify.css';


import Loader from './Components/Loader/Loader';


const Login = React.lazy(() => import('./Pages/Auth/Login'));
const Layout = React.lazy(() => import('./Pages/Layout/Layout'));


function App() {
  let YOUR_TOKEN = "dc4de3743e42be7b4ce49dc0da9eb6e4";
  // window.mixpanel.init("dc4de3743e42be7b4ce49dc0da9eb6e4");
  window.mixpanel.init(YOUR_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});
  return (<>
      <Suspense fallback={<Loader />}>
      <BrowserRouter>      
      <Routes>
   
        <Route path="/*" element={<Layout />} />
        <Route path='/login'  element={<Login /> } />
        
      </Routes>
      </BrowserRouter>
      </Suspense>

      </>
    );
}

export default App;
